.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.file-container {
    display: flex;
    flex-direction: column;

    padding: 16px;
    border: 1px #ced4da solid;
    border-radius: 3px;
    width: 100%;
    display: inline-block;

    margin-bottom: 1em;
}

aside {
    display: block;
}

.space-on-top {
    margin-top: 1em;
}

.logo {
    display: inline-block;
    width: 2em;
    max-width: none;
    margin-top: 10px;
}

.mono-font-plz {
	font-family: "Courier New", Courier, monospace;
}

.red {
	color: #dd0000
}
